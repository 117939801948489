import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en-US",
  lng: "en-US",
  resources: {
    "en-US": {
      translation: require("./locales/en/translations.json"),
    },
  },
  // ns: ['translations'],
  // defaultNS: 'translations'
});

i18n.languages = ["en-US"];

export default i18n;
