import React, {useState} from "react";

import { gql, useQuery } from "@apollo/client";
import {LessonsListFragment} from "./LessonsListFragment";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {GraphQLClient} from "../Clients";
import {withRouter} from "../../Utils/withRouter";

const Lessons = (props) => {
  let match = { params: useParams() };
  let { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  let locale = i18n.language;

  const [textSearched, setTextSearched] = useState(searchParams.get("search"))

  const doSearch = (element) => {
    let searchValue = element.target.value
    navigate({
      pathname: location.pathname,
      search: searchValue ? `?search=${searchValue}` : ""
    });

    setTextSearched(searchValue)
  }

  const LessonsList = (props) => {
    const { t, i18n } = props;

    const GET_LESSONS = gql`
      query getLessons{
        lessonsCollection(locale:"${locale}", limit:500){
          items{
            sys{
              id
            }
            idName
            name
            slug
            cumulativeActivitiesCollection(limit:1){
              items{
                sys{
                  id
                }
              }
            }
            linkedFrom(allowedLocales: ["en-US"]){
              unitsCollection(limit:1){
                items{
                  sys{
                    id
                  }
                  linkedFrom(allowedLocales: ["en-US"]){
                    gradesCollection(limit:1){
                      items{
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const { loading, error, data } = useQuery(GET_LESSONS, {
      client: GraphQLClient.getOrCreate(),
      errorPolicy: "ignore"
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    let itemsToShow = (textSearched != "" && textSearched != null) ? data.lessonsCollection.items
    .filter(i => i.name.toLowerCase().includes(textSearched.toLowerCase()) || i.idName.toLowerCase().includes(textSearched.toLowerCase())) : data.lessonsCollection.items;

    return !loading && data ? (
        <LessonsListFragment items={itemsToShow} t={t} i18n={i18n} />
    ) : null;
  };

  return (
      <div className={`lessons`}>
        <div className="container mt-3  mb-5">
          <div className="row">
            <div className="col-3">

            </div>
            <div className="col-6">
              <div className="text-center">
                <h2>{i18n.t("navbar.lessons")}</h2>
              </div>
            </div>
            <div className="col-3">
              <input type="text" className={'form-control'} placeholder={'Buscar'} defaultValue={textSearched} onChange={doSearch}/>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <LessonsList t={i18n.t} i18n={i18n} />
            </div>
          </div>
        </div>
      </div>
  );
}

export default withRouter(Lessons);
